.App {
    text-align: center;
}

input {
    border-radius: 0 !important;
    border-top: none !important;
    border-right: none !important;
    border-left: none !important;
    padding: 0 !important;
    outline: none !important;
    background: transparent;
}

.form-input{
    border-radius:10px !important;
    padding: 0 10px !important;
    background-color: #EAEAFB !important;
}

.form-input:disabled{
    background-color: #bebef5 !important;
}

a {
    color: #E64C4C;
}

a:hover {
    color: #E64C4C;
}

.gs-clickable-text {
    font-size: 14px;
    color: #7B7B7B;
    text-decoration-line: underline
}

.gs-clickable-text:hover {
    cursor: pointer;
}

.btn-primary, button {
    background-color: #E64C4C !important;
    border-color: #E64C4C !important;
    border-width: 2px;
    border-radius: 10px;
    opacity: 1 !important;
}

.btn-primary:hover, button:hover, .btn-secondary{
    background-color: #fff !important;
    border-color: #E64C4C !important;
    border-width: 2px;
    color: #E64C4C !important;
    opacity: 1 !important;
}

.btn-primary:disabled {
    background-color: #E64C4C !important;
    border-color: #E64C4C !important;
    opacity: 0.65 !important;
    pointer-events: none;
}

.btn-secondary:hover, .btn-secondary:focus{
    color: #fff !important;
    background-color: #E64C4C !important;
    border-color: #E64C4C !important;
    border-width: 2px;
    border-radius: 10px;
    opacity: 1 !important;
}

.btn-secondary:disabled {
    pointer-events: none;
}

.btn-primary:disabled:hover {
    background-color: #E64C4C !important;
    border-color: #E64C4C !important;
    opacity: 0.65 !important;
}

a.btn-primary {
    background-color: #E64C4C !important;
    border: 2px solid #E64C4C;
    border-radius: 10px;
    display: block;
    text-align: center;
    padding-top: 1rem;
    padding-bottom: 1rem;
    color: #fff;
    opacity: 1 !important;
}

a.btn-primary:hover {
    background-color: #fff !important;
    border-color: #E64C4C !important;
    color: #E64C4C !important;
    text-decoration: none;
    opacity: 1 !important;
}

a.btn-pink {
    background-color: #fff !important;
    border: 2px solid #E64C4C;
    border-radius: 10px;
    display: block;
    text-align: center;
    padding-top: 1rem;
    padding-bottom: 1rem;
    color: #E64C4C;
    opacity: 1 !important;
}

a.btn-pink:hover {
    background-color: #E64C4C !important;
    color: #fff !important;
    text-decoration: none;
    opacity: 1 !important;
}

a.btn-secondary {
    background-color: #D4EEEF !important;
    border: 2px solid #299898;
    border-radius: 10px;
    display: block;
    text-align: center;
    padding-top: 1rem;
    padding-bottom: 1rem;
    color: #299898;
    opacity: 1 !important;
}

a.btn-secondary:hover {
    background-color: #299898 !important;
    border-color: #299898 !important;
    color: #D4EEEF !important;
    text-decoration: none;
    opacity: 1 !important;
}

.btn-blue {
    background-color: #fff !important;
    /* border: 2px solid #1D65B1; */
    border-color: #1D65B1 !important;
    padding-top: 1rem;
    padding-bottom: 1rem;
    border-radius: 10px;
    color: #1D65B1;
    opacity: 1 !important;
}

.btn-blue:hover {
    background-color: #1D65B1 !important;
    border-color: #1D65B1 !important;
    color: #fff !important;
    text-decoration: none;
    opacity: 1 !important;
}

.btn-green-secondary, .btn-green:hover, .btn-green:focus {
    background-color: #fff !important;
    border: 2px solid #A8CA71 !important;
    border-radius: 10px;
    color: #A8CA71 !important;
    opacity: 1 !important;
}

.btn-green, .btn-green-secondary:hover, .btn-green-secondary:focus {
    background-color: #A8CA71 !important;
    border-color: #A8CA71 !important;
    color: #fff !important;
    text-decoration: none;
    opacity: 1 !important;
}

.btn-yellow-secondary, .btn-yellow:hover, .btn-yellow:focus {
    background-color: #fff !important;
    /* border: 2px solid #1D65B1; */
    border-color: #F6BD42 !important;
    border-radius: 10px;
    color: #F6BD42 !important;
    opacity: 1 !important;
}

.btn-yellow, .btn-yellow-secondary:hover, .btn-yellow-secondary:focus {
    background-color: #F6BD42 !important;
    border-color: #F6BD42 !important;
    color: #fff !important;
    text-decoration: none;
    opacity: 1 !important;
}

.btn-purple-secondary, .btn-purple:hover, .btn-purple:focus {
    background-color: #fff !important;
    /* border: 2px solid #1D65B1; */
    border-color: #4542C2 !important;
    border-radius: 10px;
    color: #4542C2 !important;
    opacity: 1 !important;
}

.btn-purple, .btn-purple-secondary:hover, .btn-purple-secondary:focus {
    background-color: #4542C2 !important;
    border-color: #4542C2 !important;
    color: #fff !important;
    text-decoration: none;
    opacity: 1 !important;
}

.btn-purple:disabled{
    background-color:#7676ee !important;
    border-color: #7676ee !important;
}

.btn-teal-secondary, .btn-teal:hover, .btn-teal:focus {
    background-color: #fff !important;
    border-radius: 10px;
    border: 2px solid #57D7C7 !important;
    color: #36AB9D !important;
    opacity: 1 !important;
}

.btn-teal, .btn-teal-secondary:hover, .btn-teal-secondary:focus {
    background-color:  #57D7C7 !important;
    border-color: #57D7C7 !important;
    color: #fff !important;
    text-decoration: none;
    opacity: 1 !important;
}

.btn-teal:disabled, .btn-teal-secondary:disabled, .btn-green:disabled, .btn-green-secondary:disabled, .btn-purple:disabled, .btn-purple-secondary:disabled{
    pointer-events: none;
}

a.btn-blue {
    background-color: #fff !important;
    border: 2px solid #1D65B1;
    border-radius: 10px;
    display: block;
    text-align: center;
    padding-top: 1rem;
    padding-bottom: 1rem;
    border-radius: 10px;
    color: #1D65B1;
    opacity: 1 !important;
}

a.btn-blue:hover {
    background-color: #1D65B1 !important;
    border-color: #1D65B1 !important;
    color: #fff !important;
    text-decoration: none;
    opacity: 1 !important;
}

a.btn-green {
    background-color: #fff !important;
    border: 2px solid #299898;
    border-radius: 10px;
    display: block;
    text-align: center;
    padding-top: 1rem;
    padding-bottom: 1rem;
    color: #1D65B1;
    opacity: 1 !important;
}

a.btn-green:hover {
    background-color: #299898 !important;
    border-color: #299898 !important;
    color: #fff !important;
    text-decoration: none;
    opacity: 1 !important;
}

.gs-text {
    background: transparent !important;
    border: none !important;
    padding: 0 !important;
    color: #3c3c3c;
}

.gs-link {
    color: #E64C4C;
}

.gs-link:hover {
    text-decoration: underline;
}

body {
    color: #3C3C3C;
}

body.gs {
    overflow-x: hidden;
}

body.modal-open {
    overflow: hidden !important;
    padding: 0 !important;
}

.send-to-back {
    z-index: -1000;
}

.modal-backdrop {
    z-index: 10005;
}

.modal {
    z-index: 10010;
}

* {
    font-family: 'Poppins', sans-serif, 'FontAwesome';
}

.no-underline:hover {
    border-bottom: none !important;
}

.hover-underline:hover {
    text-decoration: underline;
}

p.gs a {
    color: #3C3C3C;
}

img.theme-icon {
    padding-top: 5rem;
}

.gs-color {
    color: #3C3C3C;
}

.gs-navbar-logo img {
    height: 6rem;
}

.gs-navbar {
    z-index: 10001;
    top: 0;
    padding: 0;
    background-color: transparent;
}

.gs-block {
    padding-top: 10rem;
    padding-bottom: 5rem;
}

.gs-logo {
    white-space: nowrap;
    height: 3.5rem;
    width: 15.75rem;
}

.gs-logo img {
    height: 100%
}

.bg-red {
    background-color: #DF7260 !important;
    color: #fff;
}

.bg-pink {
    background-color: #FFD4C4 !important;
}

.bg-yellow {
    background-color: #F1BC00 !important;
}

.bg-light-yellow {
    background-color: #FEE19B !important;
}

.bg-light-blue {
    background: #F2F9FA;
}

.bg-dark-blue {
    background: #D4EEEF;
}

.bg-blue {
    background-color: #69CDCD !important;
}

.bg-grey {
    background-color: #F7F8F9 !important;
}

.text-light-blue {
    color: #d7ecfdff !important;
}

.text-blue {
    color: #69CDCD !important;
}

.text-dark-blue {
    color: #227AD3 !important;
}

.text-red {
    color: #E64C4C !important;
}

.text-aqua {
    color: #9AD6D6 !important;
}

.text-pink {
    color: #EC9783 !important;
}

.text-yellow{
    color: #FECB48 !important;
}

.text-black {
    color: #3C3C3C !important;
}

.gs-content {
    min-height: 100vh;
}

.gs-social-media a {
    font-size: 3rem;
    color: #3c3c3c;
    padding: 1rem;
}

.gs-social-media a:hover {
    font-size: 3rem;
    color: #E64C4C;
    padding: 1rem;
}

.gs-home-preadmit {
    max-width: 30rem;
}

.gs-form-container {
    max-width: 30rem;
    padding-top: 7rem;
}

.gs-login-image {
    position: absolute;
    bottom: 0;
    width: 100%;
    max-height: 80%;
    padding: 0 5rem 0 5rem;
}

.gs-password-image {
    position: absolute;
    bottom: 0;
    width: 100%;
    max-height: 80%;
    margin: 0rem 8rem 0rem -2rem;
}

.nav-pills .nav-link {
    border-radius: 0;
    color: #3D3D3D;
    padding: 1rem 0rem 1rem 2.25rem;
}

.nav-pills .nav-link {
    border-radius: 0;
    color: #3D3D3D;
    padding: 1rem 0rem 1rem 2.25rem;
}

.nav-pills .nav-link.disabled {
    border-radius: 0;
    color: #ABAAAA;
    padding: 1rem 0rem 1rem 2.25rem;
}

.nav-pills .nav-link:hover {
    color: #9AD6D6;
}

.nav-pills .nav-link.active {
    background-color: #D4EEEF;
    border-left: 0.75rem solid #9AD6D6;
    padding-left: 1.5rem;
    color: #3D3D3D;
    box-shadow: 0px 4px 4px rgba(212, 238, 239, 0.37);
}

.gs-htg-block {
    /* padding-top: 3rem; */
    padding-bottom: 3rem;
}

.get-connected{
    margin-bottom:32px;
    margin-top: 24px;
}

.gs-dashboard-nav {
    position: fixed;
    height: 100vh;
    width: 18.5rem;
}

.gs-dashboard-nav-mobile {
    background-color: #F8F8F8;
}

.gs-dashboard-content {
    background-color: #F8F8F8;
    min-height: 100vh;
    margin-left: 18.5rem;
    width: calc(100% - 18.5rem);
    padding-top: 6rem;
}

.gs-home-container, .gs-account-container {
    /* padding-top: 4rem; */
}

.gs-team-container {
    padding: 0;
}

.gs-in-team-block{
    position: relative;
    z-index: 100;
    padding-bottom: 3rem;
}

.gs-no-team-block{
    position: relative;
    z-index: 100;
}

.gs-no-team-block .card{
    filter: drop-shadow(0px 8px 16px rgba(0, 0, 0, 0.15));
    border-radius: 4px;
    display: grid;
    grid-template-rows: auto auto auto;
    align-items: space-between;
    height: 205px;
    width: 297px;
}

.gs-no-team-block .card-body{
    display: grid;
    grid-template-columns: auto auto;
}

.gs-no-team-bg-img {
    position: fixed;
    width: calc(100% - 18.5rem);
    max-width: 1140px;
    bottom: 0px;
}

.gs-in-team-bg-img {
    position: fixed;
    width: calc((100% - 18.5rem)/3);
    bottom: 0px;
    right: 0px;
}

.gs-member-card {
    background: #FFFFFF;
    border: 1px solid #C4D0E2;
    box-sizing: border-box;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.075);
    border-radius: 7px;
    justify-items: center;
    padding: 4px 6px;
    margin-bottom: 8px;
    /* max-width: 20rem; */
}

.gs-member-card h5 {
    font-size: 14px !important;
    line-height: 15px !important;
    color: #170B31;
    margin: 6px;
}

.gs-member-card p {
    font-size: 10px;
    line-height: 15px;
    color: #7B7B7B;
    margin: 2px ;
}

.gs-submission-block {
    display: grid;
    grid-template-columns: auto auto;
    max-width: 27rem;
}

.gs-submission-details {
    background: #F5F7FA;
    color:#3C3C3C;
    border: 1px solid #C4D0E2;
    box-sizing: border-box;
    border-radius: 5px;
    padding: 4px;
    margin: 0px 4px;
    display: inline-flex;
    max-width: 100%;
    overflow: overlay;
}

.gs-submission-details:hover {
    color:#3C3C3C;
}

.gs-schedule-container, .gs-workshop-container {
}

.gs-home-submission-container {
    background: linear-gradient(99.7deg, #F09594 31.31%, #EC7070 73.17%);
    border-radius: 10px;
    padding: 2rem;
}

.gs-home-slack-container {
    background: linear-gradient(90deg, #4542C2 0%, #9EECE2 131.18%);
    border-radius: 10px;
    padding: 2rem 1rem 2rem 1rem;
    color: #fff;
}

.gs-home-hopin-container {
    background: linear-gradient(90deg, #FF4457 -6.96%, #F8DD80 43.12%);
    border-radius: 10px;
    padding: 2rem 1rem 2rem 1rem;
    color: #000;
}

.gs-home-mentor-session-container {
    background: linear-gradient(205.41deg, #FFC234 -3.55%, #FED26E 83.9%);
    border-radius: 10px;
    padding: 2rem;
    color: #3D3D3D;
}
.gs-home-mentor-session-container td, .gs-home-mentor-session-container th {
    border: none !important;
    color: #3D3D3D;
}

.gs-home-slack-container .btn, .gs-home-hopin-container .btn {
    min-width: 5rem;
    color: #227AD3;
    background-color: #fff !important;
    border-color: transparent !important;
}

.gs-home-slack-container .btn a, .gs-home-hopin-container .btn a {
    color: #227AD3 !important;
}

.gs-home-slack-container .btn a:hover, .gs-home-hopin-container .btn a:hover {
    color: #DF7260 !important;
    text-decoration: none;
}

.gs-home-faq-container {
    border-radius: 10px;
    background-color: #fff;
    padding: 2.75rem;
}

.gs-home-faq-container .card-header, .gs-home-faq-container .card-body {
    padding-left: 0;
    padding-right:0;
}

.gs-home-faq-container .card-header {
    border-bottom: 2px solid rgba(0,0,0,.125);
    padding: 1rem 0 0.75rem 0;
}

.gs-home-faq-container .card-header {
    background-color: #fff;
}

.gs-home-container .card, .gs-resources-container .card {
    border: transparent;
    border-radius: 10px;
    cursor:pointer;
}

.gs-home-container .card img, .gs-resources-container .card img{
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.gs-home-container .card .btn {
    min-width: 7rem;
}

.gs-resource-theme-partners img {
    height: 4.5rem;
    padding: 0.5rem;
}

.gs-logout-button {
    position:absolute;
    bottom: 0;
    border-radius: 0;
    padding: 1rem 0rem 2.25rem 2.25rem;
}

.gs-event-block {
    /* background-color: #D4EEEF; */
    padding: 0.5rem;
}

.fc .fc-daygrid-day.fc-day-today {
    background-color: transparent !important;
}

.fc-daygrid-dot-event {
    display: block;
}

.fc td, .fc table {
    border-top: none !important;
    border-bottom: none !important;
}

.fc-daygrid td, .fc-daygrid th, .fc-daygrid table {
    border-style: none !important;
}

.fc-dayGrid3Day-view th, .fc-dayGrid3Day-view .fc-daygrid-day {
    border-top: none !important;
    border-bottom: none !important;
    border-left: 2px solid #3C3C3C !important;
    border-right: 2px solid #3C3C3C !important;
}

.fc-dayGrid-button.fc-button-primary, .fc-dayGrid3Day-button.fc-button-primary {
    color: #E64C4C !important;
    border-color: #FFCFCE !important;
    background: #FFCFCE !important;
    width: 6rem;
    border-width: 2px !important;
    border-radius: 10px !important;
}

.fc-dayGrid-button.fc-button-primary:hover, .fc-dayGrid3Day-button.fc-button-primary:hover {
    background-color: #fff !important;
    border-color: #E64C4C !important;
    color: #E64C4C !important;
    opacity: 1 !important;
}

.fc-button-primary:not(:disabled):active, .fc-button-primary:not(:disabled).fc-button-active {
    background-color: #E64C4C !important;
    color: #fff !important;
    border-color: #E64C4C !important;
}

.fc-today-button.fc-button.fc-button-primary, .fc-prev-button.fc-button.fc-button-primary, .fc-next-button.fc-button.fc-button-primary {
    background-color: transparent !important;
    border: none !important;
    color: #3D3D3D !important;
    font-size: 1.5rem;
    opacity: 1 !important;
}

.fc-today-button.fc-button.fc-button-primary:hover, .fc-prev-button.fc-button.fc-button-primary:hover, .fc-next-button.fc-button.fc-button-primary:hover {
    color: #E64C4C !important;
}

.fc-day-today {
    color: #E64C4C !important;
}

.nav-link {
    color: #3C3C3C !important;
}

.nav-link.active {
    color: #7676ee!important;
}

.navbar-toggler {
    background-color: #fff !important;
    border: none;
}

@media (min-width: 1450px){
    .gs-in-team-bg-img{
        right: calc((100%  - 18.5rem - 1140px)/2);
    }
}

@media (max-width: 767.5px) {
    .gs-dashboard-content {
        margin-left: auto;
        margin-right: auto;
        padding-top: 0;
        width: auto;
    }
    .gs-no-team-bg-img {
        width: 100%;
        left: 0px;
    }
    .gs-in-team-bg-img {
        width: 40%;
    }
}

@media (max-width: 360px) {
    .gs-submission-block {
        grid-template-columns: 100%;
        grid-template-rows: auto auto;
    }
}

.people-header {
    display: flex;
}

.gs-hacker-card {
    background-color: #fff;
    margin-bottom: 0.5rem;
    border: none;
    border-radius: 10px;
}

.gs-hacker-card .card-body {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
}

.gs-hacker-card .card-header {
    padding: 0rem 1.5rem 0rem 1.5rem;
    border-bottom: 0px solid rgba(255, 255, 255, 0.125);
    background-color: #fff;
}

.gs-hacker-image {
    width: 40px;
    height: 40px;
}

.accordion-toggle {
    display: flex;
    align-items: center;
    margin-top: 0.7rem;
    margin-bottom: 0.7rem;
}

.accordion-border {
    border-radius: 10px;
}

.hacker_name {
    font-size: 16px;
    font-weight: bold;
}
.circle_button {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    background-color: #AAA8EF;
    border: solid 1px #AAA8EF;
    margin-left: auto;
}

.hacker_arrow {
    width: 55%;
    margin-top: 14px;
}

.hacker_arrow_rotate {
    width: 55%;
    transform: rotate(180deg);
    margin-top: 10px;
}

.display-flex {
    display: flex;
}

.info-pair-group {
    align-items: center;
    margin-bottom: 10px;
}

.info_pair {
    flex: 1;
}

.ml-auto {
    margin-left: auto;
}

.hacker-link {
    color: #000000;
}

.hacker-link:hover {
    color: #4542C2;
}

.sortby-button {
    padding-right: 0.5rem;
    padding-left: 4.5rem; /* change this to make the width longer on the left side */
    position: relative;
}

.sortby-button.open .dropdown-filterby {
    position: absolute;
    top: 95%;
    left: 0;
    z-index: 1;
}

.sortby-button.open .dropdown-sortby {
    position: absolute;
    top: 95%;
    left: 0;
    z-index: 1;
}

.dropdown-button {
    background-color: #4542C2 !important;
    border-color: #4542C2 !important;
    color: white;
    border: 0px;
    border-radius: 10px;
    height: 40px;
    width: 120px;
}

.dropdown-button:hover {
    background-color: #4542C2 !important;
    border-color: #4542C2 !important;
    color: white !important;
    border: 0px;
}

.dropdown-button:focus {
    outline: none;
}

.dropdown-button-radius {
    border-radius: 10px 10px 0px 0px;
}

.dropdown-filterby {
    width: 192px;
    height: 85px;
    background-color: #4542C2;
    color: white;
    border-radius: 10px 0px 10px 10px;
}

.dropdown-sortby {
    width: 192px;
    height: 218px;
    background-color: #4542C2;
    color: white;
    border-radius: 10px 0px 10px 10px;
}

.radio-circle {
    width: 15px;
    height: 15px;
}

.radio-text1 {
    font-size: 14px;
    margin: 13px 0px 0px 8px;
}

.radio-text2 {
    font-size: 14px;
    margin: 5px 0px 0px 8px;
}

.buttons-container {
    margin-left: auto;
}

.purple-colour {
    color: #4542C2;
}

.font-weight-bold {
    font-weight: bold;
}

.resources-card-text {
    color: rgba(21, 0, 53, 0.45); /* 40% transparent black */
    font-size: 15px;
}

.resources-col-height {
    height: 98%;
}

.resources-lightbulb {
    margin: 1rem 0rem 1rem 1rem;
    max-width: 100%;
    height: auto;
}

.resource-pdf-title {
    padding-top: 2rem;
}

.view-button {
    background-color: #4542C2 !important;
    color: #fff !important;
    text-decoration: none;
    opacity: 1 !important;
    border-radius: 10px;
    display: block;
    text-align: center;
    padding-top: 1rem;
    padding-bottom: 1rem;
    border-radius: 10px;
    font-size: 18px;
}

.view-button:hover {
    text-decoration: none;
    background-color: #5e5dbe !important;
}

.slack-img{
    height:55px;
    width:55px;
}

.join-slack{
    padding-right: 4rem;
}

.in-team-style{
    padding-left: 1rem;
    padding-top: 7rem;
}