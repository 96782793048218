  
  .popup-content {
    border-radius: 8px;
  }


  .bold{
    font-weight: bold;
  }

  .welcome{
    font-size: 40;
    align-items: center;
    justify-content: center;
    position: relative;
    top: 0%;
  }

  .popup-content-1{
    text-align: center;
    position: relative;
    top: 7%;
    padding-bottom: 1rem;
    display:flex;
    align-content:center;
    gap:20px;
    flex-direction: column;
  }

  .popup-content-2{
    text-align: center;
    position: relative;
    top: 15%;
  }

  .popup-content-3{
    text-align: center;
    position: relative;
    top: 22%;
  }

  .terms-box{
    border: 2px grey solid;
    border-radius:10px;
    padding: 10px;
    display:flex;
    flex-direction:column;
    gap:20px;
    align-content:center;
  }
  
  .waitlisted .modal-content{
    height:80vh;
    overflow:hidden;
  }

  .waitlisted{
    backdrop-filter: blur(10px);
  }

  .waitlist-img{
    margin-left: -30px;
  }

  